
import React from "react"
import { graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { normalizeSponsorGold } from "../helpers";
import { allRoutes } from "../helpers/routes"
import Img from "gatsby-image"
import Layout from "../components/layout"
import LinkBtn from "../components/linkBtn"
import GoogleMap from "../components/map"
import { 
  SocialLink,
  SocialDownload
} from "../assets/images"

export default function SponsorGold({ data }) {
  const { 
    name, 
    logo, 
    tagline, 
    descriptionJson,
    address, 
    city,
    state,
    zip,
    addressNotes, 
    phone, 
    email, 
    website,
    brochure
  } = normalizeSponsorGold(data.allContentfulGoldSponsor.edges[0].node);

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />;
      },
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
      [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
      [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
    },
  }

  return (
    <Layout>
      <header className="sponsor__header sponsor__header--gold">
        <div className="content-area sponsor__overview">
          {logo && (
            <LinkBtn className="sponsor__logo" to={website}>
              <Img fluid={logo} alt={`${name} logo`} />
            </LinkBtn>
          )}
          <div>
            <h1>{name}</h1>
            {tagline && <p className="sponsor__tagline">{tagline}</p>}
          </div>
        </div>
      </header>
      <div className="content-area">
        <article className="sponsor">
          <div className="sponsor-content">
            <div className="rich-text">
              {documentToReactComponents(descriptionJson, options)}
            </div>
          </div>
          {(address || addressNotes || phone || email || website) && (
            <div className="sponsor-sidebar">
              <h2>Contact Information</h2>
              {address && city && state && (
                <GoogleMap address={`${address} ${city} ${state}`} />
              )}
              {address && (
                <div className="sponsor__address">
                  <span>{address}</span>
                  {city && state && zip && (
                    <>
                      <span className="separator">{" "}•{" "}</span>
                      <span>{city}, {state} {zip}</span>
                    </>
                  )}
                </div>
              )}
              {addressNotes && <p className="sponsor__address-note">{addressNotes}</p>}
              <hr />
              {email && (
                <span className="contact">
                  <LinkBtn className="contact__email link--underline" to={`mailto:${email}`}>
                    {email}
                  </LinkBtn>
                </span>
              )}
              {email && phone && <span className="separator">{" "}•{" "}</span>}
              {phone && (
                <a className="contact contact__phone" href={`tel:${phone}`}>
                  {phone}
                </a>
              )}
              {(website || brochure) && (
                <ul className="social">
                  {website && (
                    <li>
                      <LinkBtn to={website} title="Website">
                        <SocialLink />
                        <span className="is-visually-hidden">
                          {website}
                        </span>
                      </LinkBtn>
                    </li>
                  )}
                  {brochure && (
                    <li>
                      <LinkBtn to={brochure} title="Brochure" download>
                        <SocialDownload />
                        <span className="is-visually-hidden">
                          {brochure}
                        </span>
                      </LinkBtn>
                    </li>
                  )}
                </ul>
              )}
            </div>
          )}
        </article>
        <div className="cta-block-bottom">
          <LinkBtn className="btn" to={allRoutes.directory}>
            Back to the Directory
          </LinkBtn>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($name: String!) {
    allContentfulGoldSponsor(filter: {vendorName: {eq: $name}}) {
      edges {
        node {
          vendorName
          vendorLogo {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          vendorTagline
          vendorDescription {
            childContentfulRichText {
              html
            }
            json
          }
          vendorAddress {
            lat
            lon
          }
          vendorAddressStreet
          vendorAddressCity
          vendorAddressState
          vendorAddressZip
          vendorAddressNotes
          vendorPhoneNumber
          vendorEmailAddress
          vendorWebsite
          vendorBrochure {
            file {
              url
            }
          }
        }
      }
    }
    exhibitHall: file(relativePath: { eq: "exhibit-hall.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`